<script setup lang="ts">
  import { YandexMap, YandexMapDefaultSchemeLayer, YandexMapDefaultFeaturesLayer, YandexMapMarker, YandexMapListener, YandexMapControls, YandexMapZoomControl } from 'vue-yandex-maps';
  import type { LngLat } from '@yandex/ymaps3-types';

  import type { ClinicInterface } from '~/EastclinicVueApi';

  interface Props {
    showTitle: boolean;
  }

  defineProps<Props>();

  const { clinics } = storeToRefs(useClinicsStore());

  const controlStatus = ref<'clinics'|'map'>('map');

  const isDesktop = useMediaQuery('(min-width: 1023px)');
  const isMobile = useMediaQuery('(max-width: 767px)');

  const currentPopupClinicId = ref(-1);

  const currentMarkerElement = ref<HTMLElement|null>(null);
  const { top, right, bottom, left } = useElementBounding(currentMarkerElement);

  const mapHeight = computed(() => {
    return isMobile.value ? '360px' : '476px';
  });

  const popupPosition = computed(() => {
    return `${top.value - 120}px ${right.value}px ${bottom.value}px ${left.value - 120}px`;
  });

  const popupClinicImage = (clinic:ClinicInterface|undefined) => {
    if (clinic === undefined) {
      return;
    }
    let img = 'https://eastclinic.ru/assets/resourceimages/3368/_210x140/IMG_2646_210x140.webp';
    if (clinic.content && Array.isArray(clinic.content) && clinic.content.length > 0) {
      const imgs = clinic.content.filter((c) => c.type === '210x140');
      if (imgs && imgs.length > 0) { img = imgs[0].url; }
    }
    return img;
  };

  const showClinics = computed(() => {
    return isDesktop.value || controlStatus.value === 'clinics';
  });

  const showMap = computed(() => {
    return isDesktop.value || controlStatus.value === 'map';
  });

  const currectPopupClinic = computed(() => {
    return clinics.value?.find((c) => c.id === currentPopupClinicId.value);
  });
</script>

<template>
  <div class="main-container">
    <div class="map-container">
      <div v-if="showTitle" class="map-top-text">
        Адреса наших клиник
      </div>
      <div class="map-toggle">
        <div :class="`map-toggle-btn ${controlStatus === 'map' ? 'toggle-active':''}`" @click="controlStatus='map'">
          На карте
        </div>
        <div :class="`map-toggle-btn ${controlStatus === 'clinics' ? 'toggle-active':''}`" @click="controlStatus='clinics'">
          Списком
        </div>
      </div>
      <ClientOnly>
        <div class="clinics-with-map">
          <div v-if="showClinics" class="clinic-results-container">
            <!-- id !== 42 это данные от компонента "Consultation" -->
            <div class="overflow-container">
              <SearchPanelClinicResult
                v-for="clinic in clinics?.filter(c => c.id !== 42)"
                :key="clinic.id"
                :circle-color="String(clinic.node_color)"
                :text-main="clinic.node_address"
                :text-secondary="clinicGetTextSecondary(clinic?.way, clinic.way_time)"
                :text-address="clinic?.address ?? ''"
                class="clinic-result-map"
                @click="navigateTo(clinic.contact_url)"
              />
            </div>
          </div>
          <div v-if="showMap" class="map-wrapper">
            <YandexMap
              :settings="{
                location: {
                  center: [37.617644, 55.755819],
                  zoom: isDesktop ? 10 : 9,
                },
                showScaleInCopyrights: true,
                behaviors: ['drag', 'pinchZoom', 'dblClick']
              }"
              width="100%"
              :height="mapHeight"
              class="map-radius"
            >
              <YandexMapDefaultSchemeLayer />
              <YandexMapDefaultFeaturesLayer />
              <YandexMapControls :settings="{ position: 'right' }">
                <YandexMapZoomControl />
              </YandexMapControls>
              <YandexMapListener
                :settings="{
                  onUpdate: () => currentPopupClinicId = -1
                }"
              />

              <YandexMapMarker
                v-for="clinic in clinics?.filter(c => c.id !== 42)"
                :key="clinic.id"
                :settings="{coordinates: [clinic.lon, clinic.lat] as LngLat}"
                position="top-center left-center"
              >
                <IconMapMarker
                  :ref="(el: HTMLElement) => clinic.id === currentPopupClinicId ? currentMarkerElement = el : el"
                  filled
                  :font-controlled="false"
                  class="marker"
                  @click="currentPopupClinicId = clinic.id"
                />
              </YandexMapMarker>
            </YandexMap>
            <div v-if="currentPopupClinicId !== -1" :class="`${isMobile?'mobile-popup':'desktop-popup'}`">
              <div class="popup-container">
                <div class="popup-img" :style="{'background-image': `url(${popupClinicImage(currectPopupClinic)})`}"></div>
                <div class="popup-content-container">
                  <div class="popup-content">
                    <span class="popup-title-text">Ист Клиник {{ clinicGetAddressInAdpositionalCase(currectPopupClinic?.node_address) }}</span>
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <span class="popup-address" v-html="currectPopupClinic?.address"></span>
                    <button type="button" class="popup-navigate-button" @click="navigateTo(currectPopupClinic?.contact_url)">
                      Перейти
                    </button>
                  </div>
                </div>
                <div class="popup-close-container" @click="currentPopupClinicId = -1">
                  <IconClose class="icon-close" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ClientOnly>
    </div>
  </div>
</template>

<style scoped lang="scss">
.map-wrapper {
  position: relative;
  width: calc(100% - 410px);
  @media (max-width: 1023px) {
    width: 100%;
  }
}
.marker {
  max-width: unset;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.map-container {
  display: flex;
  flex-direction: column;
  gap: 39px;
  padding-top: 50px;
  padding-bottom: 100px;
  @media (max-width: 1023px) {
    gap: 16px;
    padding-top: 40px;
    padding-bottom: 80px;
  }
}
.map-top-text {
  font-family: 'Roboto';
  color: #262633;
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.085px;
  @media (max-width: 1023px) {
    font-size: 24px;
    line-height: 28px;
  }
}
.map-radius {
  position: relative;
  border-radius: 25px;
  overflow: hidden;
}
.clinics-with-map {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.clinic-results-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 380px;
  border-radius: 0 0 1.6rem 0;
  padding: 12px 0;
  @media (max-width: 1023px) {
    width: 100%;
  }
}
.overflow-container {
  @media (min-width: 1023px) {
    @include scroll;
    position: absolute;
    top: -230px;
    left: -16px;
    max-height: 472px; //same as desktop map height
    padding-right: 8px; // for distance between scroll
    overflow: hidden;
    overflow-y: auto;
  }
}
.clinic-result-map {
  border-radius: 16px;
  padding: 12px 16px;
  /* padding: 8px 16px; */
}
.map-toggle {
  display: none;
  background-color: #F2F3F6;
  border-radius: 15px;
  padding: 2px;
  @media (max-width: 1023px) {
    display: flex;
    align-items: center;
  }
}
.map-toggle-btn {
  width: 100%;
  text-align: center;
  padding: 14px 16px;
  border-radius: 14px;
  background-color: #F2F3F6;
  color: #666E81;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  cursor: pointer;
  &:hover {
    background-color: white;
    box-shadow: 0px 2px 24px 0px rgba(135, 143, 162, 0.15);
    color: #262633;
  }
}
.toggle-active {
  background-color: white;
  box-shadow: 0px 2px 24px 0px rgba(135, 143, 162, 0.15);
  color: #262633;
}
.desktop-popup {
  position: fixed;
  inset: v-bind(popupPosition);
}

.mobile-popup {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.popup-container {
  position: relative;
  width: 280px;
  height: 258px;
  border-radius: 24px;
  background-color: white;
  box-shadow: 0px 8px 34px 0px rgba(0, 0, 0, 0.24);
}

.popup-img {
  width: 280px;
  height: 141px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  background: lightgray 50% / cover no-repeat;
}
.popup-content-container {
  position: absolute;
  bottom: 0;
  z-index: 1;
  border-radius: 24px;
  width: 100%;
  background: white;
  padding: 16px;
}
.popup-content {
  display: flex;
  flex-direction: column;
}

.popup-title-text {
  color: #262633;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}
.popup-address {
  color: #666E81;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.048px;
  margin-top: 4px;
  margin-bottom: 16px;
}

.popup-navigate-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  padding: 16px 32px;
  border-radius: 15px;
  background: #F2F3F6;
  cursor: pointer;
  color: #262633;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}

.popup-close-container {
  position: absolute;
  right: 16px;
  top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50px;
  background: white;
  cursor: pointer;
}
.icon-close {
  font-size: 20px;
  margin: 0;
  color: #262633;
}
</style>
